import {
    List,
    Toolbar,
    SaveButton,
    DeleteButton,
    Datagrid,
    TextField,
    BooleanField,
    DateField,
    SimpleForm,
    TextInput,
    BooleanInput,
    RadioButtonGroupInput,
    useRecordContext,
    ReferenceManyField,
    ReferenceField,
    useGetIdentity,
    AutocompleteArrayInput,
    ReferenceArrayInput,
    SelectInput,
    TopToolbar, 
    FilterButton, 
    Button,
    useTranslate
} from 'react-admin';

import { CreateInDialogButton, EditInDialogButton } from '@react-admin/ra-form-layout';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';

const statuses = [{id:'none',name:'resources.conversation_notes.statuses.none'},{id:'open',name:'resources.conversation_notes.statuses.open'},{id:'closed',name:'resources.conversation_notes.statuses.closed'}]

export const CustomEditToolbar = () => (
    <Toolbar sx={{ justifyContent: 'space-between' }}>
        <SaveButton />
        <DeleteButton redirect={false} />
    </Toolbar>
);
  

const AddNoteForm = () => {  
    const { data: identity, isLoading: identityLoading } = useGetIdentity(); 
    const record = useRecordContext(); // Gebruik `useRecordContext` hier correct
    return (
    <CreateInDialogButton fullWidth label="resources.conversation_notes.add_note" ButtonProps={{ variant: 'text' }} title="resources.conversation_notes.add_note">
        <SimpleForm
        defaultValues={{
            created_by: identity?.id, 
            conversation_id: record?.id, 
            }}        
        >
            <TextInput source="content" label="resources.conversation_notes.fields.content" required fullWidth multiline inputProps={{ style: { minHeight: '150px' } }} />
            <BooleanInput source="is_private" label="resources.conversation_notes.fields.is_private" />
            <RadioButtonGroupInput source="status" choices={statuses} defaultValue={'none'} label="resources.conversation_notes.fields.status" />
        </SimpleForm>
    </CreateInDialogButton>
    );
  };

const EditNoteButton = () => {  
    return (
    <EditInDialogButton fullWidth inline resource='conversation-notes' title="resources.conversation_notes.edit_note">
        <SimpleForm toolbar={<CustomEditToolbar/>}>
            <TextInput source="content" required fullWidth multiline inputProps={{ style: { minHeight: '150px' } }} label="resources.conversation_notes.fields.content" />
            <BooleanInput source="is_private" label="resources.conversation_notes.fields.is_private" />
            <RadioButtonGroupInput source="status" choices={statuses} defaultValue={'none'} label="resources.conversation_notes.fields.status" />
        </SimpleForm>
    </EditInDialogButton>
    );
  };  


export const ConversationsConversationNotesList = () => {
    const record = useRecordContext();    
    if (!record) {
        return null; // Of een placeholder/loading state
    }
    return (        
        <ReferenceManyField reference="conversation-notes" target="conversation_id" source="id">
            <AddNoteForm />
            <Datagrid bulkActionButtons={false} size="medium">
                <TextField source="content" label="resources.conversation_notes.fields.content" />
                <BooleanField source="is_private" label="resources.conversation_notes.fields.is_private" />
                <ReferenceField source="created_by" reference="users" link={false} label="resources.conversation_notes.fields.created_by" emptyText='resources.generic.unavailable'>
                    <TextField source="first_name" />
                </ReferenceField>
                <TextField source="status" label="resources.conversation_notes.fields.status" />
                <DateField source="created_at" label="resources.conversation_notes.fields.created_at" />
                <EditNoteButton/>
            </Datagrid>
        </ReferenceManyField>
    )
};


const ConversationNotesListActionValue = (id:any, resource:any, record:any) => {
    return `/conversations/${record.conversation_id}/show`;
};

const filterToQuery = (searchText: any) => ({ name: `%${searchText}%` });
const ConversationNotesFilters = [
    <ReferenceArrayInput source="conversation.assistant_id" reference="assistants" alwaysOn>
        <AutocompleteArrayInput 
            optionText="name" 
            filterToQuery={filterToQuery}
            label="Assistants"
        />
    </ReferenceArrayInput>,    
    <SelectInput source="status" choices={statuses} label="Status" alwaysOn />,
    <BooleanInput source="is_private" label="Is Private" alwaysOn />
];

const ConversationNotesListActions = () => (
    <TopToolbar>
        <Button
            component={Link}
            to="/conversations"
            label="resources.conversation_notes.back_to_conversations"
        >
            <ArrowBackIcon />
        </Button>
        <FilterButton />
    </TopToolbar>
);

export const ConversationNotesList = () => {
    const translate = useTranslate();
    return (        
        <List 
            resource='conversation-notes'
            filters={ConversationNotesFilters}
            sort={{ field: 'created_at', order: 'DESC' }}
            actions={<ConversationNotesListActions />}
        >
            <Datagrid 
                bulkActionButtons={false} 
                size="medium"
                rowClick={ConversationNotesListActionValue}
            >
                <DateField 
                    source="created_at" 
                    label="resources.conversation_notes.fields.created_at" 
                    options={{ year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' }} /* @todo: fix this workaround and implement TZ correctly */ 
                    locale="nl-NL"
                    transform={(value: string) => new Date(value+'+00:00')}
                />
                <TextField source="content" label="resources.conversation_notes.fields.content" />
                <ReferenceField source="conversation_id" reference="conversations" label={translate('resources.assistants.name', { smart_count: 1 })}>
                    <ReferenceField source="assistant_id" reference="assistants">
                        <TextField source="name" />
                    </ReferenceField>                
                </ReferenceField>
                <BooleanField source="is_private" label="resources.conversation_notes.fields.is_private" />
                <ReferenceField source="created_by" reference="users" link={false} label="resources.conversation_notes.fields.created_by">
                    <TextField source="first_name" />
                </ReferenceField>
                <TextField source="status" label="resources.conversation_notes.fields.status" />
            </Datagrid>
        </List>
    )
};

export default ConversationNotesList;